<template>
  <div>
    <article>
      <h3 class="center">《歌手声音生成服务条款》</h3>
      <p style="text-align: right">更新日期：2024年6月11日</p>
      <p style="text-align: right">生效日期：2024年6月11日</p>
      <p>
        1.当您使用AI歌手功能生成自定义歌手声音（以下简称“本服务”）时，我们需要从您的清唱录音音频文件中提取您的音色进而通过技术手段生成您的歌手声音模型。录音音频文件是敏感个人信息，我们将通过加密方式进行传输、存储和保护。在生成您的歌手声音模型后，我们将对清唱录音音频文件进行删除处理。更多个人信息处理规则，请见<a
          :href="privacyUrl"
          >《隐私政策》</a
        >
        。
      </p>
      <p>
        2.
        <span class="bold"
          >您阅读并主动同意《歌手声音生成服务条款》或开始使用本服务即视为您许可我们在实现本服务并不断完善本服务的过程中使用您的声音权；该授权为永久的非独家许可，许可后不可撤销，授权范围为全球。</span
        >
      </p>
      <p>
        3. 您还可以选择向我们及快音相关平台用户开放您创建的歌手声音，<span class="bold"
          >当您主动点击生成，即代表您许可我们及快音相关平台的用户使用您的歌手声音及歌手名称用于生成歌曲，该许可为永久的非独家许可，授权范围为全球。</span
        >如您不再希望开放您的歌手声音，您可以删除您的歌手声音或通过客服联系我们进行关闭处理。但请您注意，<span
          class="bold"
          >此前已利用您的歌手声音及歌手名称生成的作品，将继续存在，该等范围内的许可无法撤回。</span
        >
      </p>
      <p>
        4.
        您在使用本服务过程中，应注意保护他人的人格权和知识产权。原则上您应使用本人的声音，不应利用他人的声音、姓名通过本服务生成歌手声音，除非您已取得相关权利人的明确授权许可。因您未取得他人许可，擅自使用他人的声音、姓名通过本服务生成歌手声音产生的法律责任和后果，应由您独自承担，如对我们及快音相关平台的用户造成损失的，我们及快音相关平台的用户有权向您追偿。
      </p>
      <p>
        5.
        您通过使用本服务生成的歌手声音模型是基于我们专有技术实现，不支持脱离快音相关平台使用；未经我们同意，您不得用于任何商业目的。
      </p>
      <p>6. 歌手声音模型生成后，您可以按照平台指引进行修改，修改后的音色将直接替代原有音色，请您谨慎修改。</p>
      <p>
        7.本服务条款是<a :href="userAgreementUrl">《用户服务协议》</a>
        及快音其他现有的平台规则的有效组成部分，本条款未尽事宜以前述协议和平台规则为准。
      </p>
    </article>
  </div>
</template>

<script>
  export default {
    name: '',
    components: {},
    props: {},
    data() {
      return {
        privacyUrl: 'https://landing.happyky.cn/privacy-protocol',
        userAgreementUrl: 'https://landing.happyky.cn/user-agreement',
      };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    /*padding-left: constant(safe-area-inset-left);*/
    /*padding-left: env(safe-area-inset-left);*/
    /*padding-right: constant(safe-area-inset-right);*/
    /*padding-right: env(safe-area-inset-right);*/
    /*padding-bottom: constant(safe-area-inset-bottom);*/
    /*padding-bottom: env(safe-area-inset-bottom);*/
  }

  article {
    margin: 0 4%;
  }

  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 16px;
  }
  h4 {
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    text-indent: 2em;
    margin: 12px 0;
    font-size: 13px;
  }
  .bold {
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  ul > li {
    margin: 5px 0;
    font-size: 13px;
    list-style: none;
  }
  ul > li > span {
    display: inline-block;
    margin: 4px 0;
  }
  ul > li > span.bold {
    display: inline;
  }
  i {
    font-style: italic;
  }

  .table {
    margin: 15px 0;
    border: 1px solid #aaa;
    width: 100%;
    border-bottom: 0;
  }
  .t-head {
    background: #c6c6c6 no-repeat scroll 0 0;
  }
  .t-head ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .t-head ul li {
    width: 20%;
    text-align: center;
  }
  .t-body {
    background-color: #fff;
  }
  .t-body ul {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #aaaaaa;
  }
  .t-body ul li {
    position: relative;
    width: 20%;
    padding: 4px;
    text-align: center;
    word-break: break-all;
  }
  a {
    color: #007aff;
    text-decoration: underline;
  }
</style>
